<template>
    <router-view :clockObject="clockObject"/>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { DateTime } from 'luxon'

import userApi from '../api/user'
import * as currentUserActions from '../store/actions/currentUser'

import localYearHolidaysApi from '../api/localYearHolidays'
import * as dataStorageActions from '../store/actions/dataStorage'

export default {
    data: function() {
        return {
            clockObject: DateTime.local(),
        }
    },
    computed: {
        ...mapState({
            loading: state => state.appStateModule.loading,
            localHolidays: state => state.dataStorage.localYearsHolidays,
            userLogged: state => state.currentUser.logged,
        })
    },
    methods: {
        ...mapActions({
            logIn: currentUserActions.USER_DOLOGIN,
            saveLocalHolidays: dataStorageActions.DATASTORAGE_SETLOCALYEARSHOLIDAYS,
        }),
        fetchLocalHolidays: function() {
            localYearHolidaysApi.getLocalYearsHolidays().then(response => {
                if(response.success) {
                    this.saveLocalHolidays(response.data);
                } 
            })
        }
    },
    watch: {
        userLogged: function(val) {
            if(val) {
                this.fetchLocalHolidays();
            }
        }
    },
    mounted: function() {
    //   userApi.currentUser().then(response => {
    //     if(response.success)
    //       this.logIn(response.data)
    //   });
        this.fetchLocalHolidays();
        setInterval(() => this.clockObject = DateTime.local(), 1000);
    }
}
</script>